<loader size="md" *ngIf="isLoading" [loading]="isLoading" class="loader"></loader>
<div class="multiple-incentive" *ngIf="!isLoading">
  <p
    class="heading2--extra-bold content-main-color"
    *ngIf="dynamicIncentiveData && !shouldOnlyDisplayIncentiveWidget"
  >
    {{ 'DYNAMIC_INCENTIVE.MY_CHALLENGES' | translate }} ({{ numberOfPrograms || 0 }})
  </p>

  <div *ngIf="!newDynamicIncentiveUIEnabled">
    <app-dynamic-incentive-program
      *ngFor="let incentive of dynamicIncentiveData"
      [incentiveProgramData]="incentive"
      [isLTR]="isLTR"
      [shouldOnlyDisplayIncentiveWidget]="shouldOnlyDisplayIncentiveWidget"
      (actionClicked)="getDynamicIncentiveProgram()"
    ></app-dynamic-incentive-program>
  </div>
  <div *ngIf="newDynamicIncentiveUIEnabled">
    <app-incentive-program-challenges
      *ngIf="dynamicIncentiveData"
      [challenges]="dynamicIncentiveData"
      [shouldOnlyDisplayIncentiveWidget]="shouldOnlyDisplayIncentiveWidget"
      (actionClicked)="getDynamicIncentiveProgram()"
    ></app-incentive-program-challenges>
  </div>
  <div class="no-incentive" *ngIf="!dynamicIncentiveData">
    <img src="{{ assetsPath + 'no-incentive.svg' }}" />
    <p class="body1--bold content-main-color">
      {{ 'DYNAMIC_INCENTIVE.NO_INCENTIVE' | translate }}
    </p>
  </div>
  <div
    class="history-container"
    *ngIf="isDynamicIncentiveHistoryData && !shouldOnlyDisplayIncentiveWidget"
  >
    <p class="heading2--extra-bold content-main-color">
      {{ 'DYNAMIC_INCENTIVE.PAST_CHALLENGES' | translate }}
    </p>
    <div class="history">
      <p class="body1--medium white-color">
        {{ 'DYNAMIC_INCENTIVE.HISTORY.HEADER' | translate }}
      </p>
      <table class="table d-none d-md-table">
        <thead>
          <tr>
            <th class="caption2--medium white-color-dimmed" scope="col">
              {{ 'DYNAMIC_INCENTIVE.HISTORY.CHALLENGE' | translate }}
            </th>
            <th scope="col" class="table-center caption2--medium white-color-dimmed">
              {{ 'DYNAMIC_INCENTIVE.HISTORY.ACCEPTANCE_DATE' | translate }}
            </th>
            <th class="caption2--medium white-color-dimmed" scope="col">
              {{ 'DYNAMIC_INCENTIVE.HISTORY.END_DATE' | translate }}
            </th>
            <th class="caption2--medium white-color-dimmed" scope="col">
              {{ 'DYNAMIC_INCENTIVE.HISTORY.PROFIT' | translate }}
            </th>
            <th class="caption2--medium white-color-dimmed" scope="col">
              {{ 'DYNAMIC_INCENTIVE.HISTORY.PROFIT_STATUS' | translate }}
            </th>
            <th class="caption2--medium white-color-dimmed" scope="col">
              {{ 'DYNAMIC_INCENTIVE.HISTORY.FEEDBACK' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let history of dynamicIncentiveHistoryData; let i = index"
            [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }"
          >
            <td class="body2--medium white-color">
              {{ 'DYNAMIC_INCENTIVE.HISTORY.DELIVER' | translate }}
              {{ history.maxTargetOrdersCount }}
              {{ 'DYNAMIC_INCENTIVE.ORDER' | translate }}
              {{ 'DYNAMIC_INCENTIVE.TYPES.' + history.orderType.toUpperCase() | translate }}
            </td>
            <td class="body2--medium white-color">{{ history.startDate }}</td>
            <td class="body2--medium white-color">{{ history.endDate }}</td>
            <td class="body2--medium white-color">
              <div class="table-profit">
                <img class="table-profit__image" src="{{ assetsPath + 'money.svg' }}" />
                <p class="body2--bold secondary-yellow-color">
                  {{ history.rewardsValue }}
                  {{ currency | currencyTranslate | translate | currencyShortName }}
                </p>
              </div>
            </td>
            <td class="body2--medium white-color">{{ history.profitStatus | translate }}</td>
            <td class="body2--medium white-color">{{ history.feedback | translate }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
